/**
 * Custom API error class to handle API errors.
 * Contains status and message properties.
 */
export class CustomApiError extends Error {
    readonly status: number;
    readonly message: string;

    constructor(message: string, status: number) {
        super(message);
        this.status = status;
        this.message = message;

        // Set the prototype explicitly for custom error class
        Object.setPrototypeOf(this, CustomApiError.prototype);
    }
}
