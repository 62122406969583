import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {GlobalService} from '../../services/global.service';
import {CustomApiError} from '../error/custom-api-error.util';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    /** exception URLs on which the alert won't trigger */
    errorExceptionRegex = new RegExp(/yardi\/getYardiConfigs/);
    rateLimiting = false;

    /**
     * List of regex where token interceptor will throw the error.
     * These errors should be handled in the component itself by showing alerts.
     */
    private endpointsToThrowError = [
        /^tenant\/[^/]+\/disable$/, // tenant/:userId/disable
        /^user\/[^/]+\/enable$/, // user/:userId/enable
    ];

    /** constructor */
    constructor(private readonly global: GlobalService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * token interceptor which adds the authentication token on every ongoing node-api request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.rateLimiting) {
            return EMPTY;
        }
        // this condition will make sure not to append token on php apis
        if (!req.url.includes('api')) {
            return next.handle(req);
        }

        const modifiedReq = this.setToken(req);
        return next.handle(modifiedReq).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * append the auth token on http request header only if the token exists in the local storage
     *
     * @returns modified request with auth token req header
     */
    private setToken(req: HttpRequest<any>): HttpRequest<any> {
        const tempToken = this.global.getLocalStorage('tpAdminToken');
        // if the content type is set then it must be a php server api and should not be touched
        if (req.headers.get('Content-Type')) {
            return req;
        }

        // if token does not exist in the local storage then return the original request
        if (tempToken === 'false' || tempToken === '') {
            return req;
        }

        return req.clone({
            headers: new HttpHeaders({
                Authorization: `Bearer ${tempToken}`,
            }),
        });
    }

    /**
     * Throws the error message if the url matches the regex.
     * Used to overide the default error handling of the interceptor.
     */
    private handleCustomError(errorResponse: HttpErrorResponse) {
        const {url, error, status} = errorResponse;
        const urlPath = new URL(url).pathname;
        const apiPath = urlPath.replace(/^\/api\/v2\//, ''); // Remove leading `/api/v2/`
        const shouldThrowError = this.endpointsToThrowError.some((regex) => apiPath.match(regex));
        if (shouldThrowError && status >= 400) {
            throw new CustomApiError(error.message, status);
        }
    }

    /**
     * handles the error handling in api failing
     */
    private handleError = (errorResponse: HttpErrorResponse) => {
        const {error, url} = errorResponse;
        this.handleCustomError(errorResponse);
        if (this.errorExceptionRegex.test(url)) {
            return of(error);
        }
        const translatedError = 'label.' + error?.errorMessage;
        if (error.message === 'User does not exist') {
            this.global.basicAlert('', 'label.user_does_not_exist', true);
        } else if (error.message === 'Unauthorized') {
            this.global.closeModal();
            //  this.global.logoutUser();
            //  this.global.basicAlert('', 'errors.unauthorized', true);
        } else if (errorResponse.status === 400 && error.message === 'Bad Request Exception' && typeof error.errorMessage === 'object') {
            this.global.basicAlert('', error.errorMessage[0], true);
        } else if (errorResponse.status === 400 && error.message === 'Bad Request Exception' && typeof error.errorMessage === 'string') {
            this.global.basicAlert('', error.errorMessage, true);
        } else if (errorResponse.status === 400 && error.message !== 'Bad Request Exception') {
            this.global.basicAlert('', translatedError, true);
        } else if (errorResponse.status === 401) {
            this.global.basicAlert('', error.message, true);
        } else if (errorResponse.status === 404 && error.message === 'risk_assessment_report_not_found') {
            this.global.hideLoading();
        } else if (errorResponse.status === 404 && url.includes('subdomain')) {
        } else if (errorResponse.status === 409) {
            this.global.basicAlert('', translatedError, true);
        } else if (errorResponse.status === 404 && url.includes('validate-convera-file')) {
            this.global.basicAlert('', translatedError, true);
        } else if (errorResponse.status === 422 && url.includes('validate-convera-file')) {
            this.global.basicAlert('', 'File cannot be parsed');
        } else if (errorResponse.status === 404 && error.message === 'idv_not_found') {
        } else if (errorResponse.status === 429) {
            this.global.hideLoading();
            this.rateLimiting = true;
            this.global.basicAlert('', 'label.too_many_requests', true);
            this.global.showLoading();
            setTimeout(() => {
                this.global.hideLoading();
                this.rateLimiting = false;
                window.location.reload();
            }, 10000);

            return EMPTY;
        } else if (errorResponse.status === 403 && error.message === 'account_locked') {
            this.global.hideLoading();
            this.global.basicAlert('', 'label.account_locked', true);
        } else if (!errorResponse.status) {
            this.global.hideLoading();
            this.rateLimiting = true;
            this.global.basicAlert('', 'label.too_many_requests', true);
            this.global.showLoading();
            setTimeout(() => {
                this.global.hideLoading();
                this.rateLimiting = false;
                window.location.reload();
            }, 10000);

            return EMPTY;
        } else {
            this.global.basicAlert('', 'errors.something_went_wrong', true);
        }
        return of(error);
    };
}
