import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, Router} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {GlobalService} from './services/global.service';
import {JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';
//import {SaveBuildingModalModule} from './pages/buildings/save-building/save-building.module';
import {GeneralPopoverModule} from './components/general-popover/general-popover.module';
// import {SendKYCModalModule} from './pages/kyc/send-kyc/send-kyc.module';
//import {DocumentDetailModalModule} from './pages/kyc/document-detail/document-detail.module';
import {DocumentViewerModalModule} from './components/document-viewer/document-viewer.module';

import {NotFoundModule} from './pages/not-found/not-found.module';
import {TokenInterceptor} from './utils/interceptors';
import * as Sentry from '@sentry/angular-ivy';
import {UserIdleModule} from 'angular-user-idle';
import {FormsModule} from '@angular/forms';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot({mode: 'md'}),
        GeneralPopoverModule,
        DocumentViewerModalModule,
        NotFoundModule,
        UserIdleModule.forRoot({idle: 2668, timeout: 32, ping: 0}),
        FormsModule,
    ],
    providers: [
        GlobalService,
        JwtHelperService,
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, fab, far);
        dayjs.extend(utc);
    }
}
